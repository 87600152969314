import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
  useTheme
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ImageLocalUrl, LocalUrl } from "../../config/env";
import { Gallery, Plus } from "../../config/icon";
import ViewVariation from '../ViewVariation';
import EditVariationPopup from '../EditVariationPopup';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  // margin:10px 0px;
  marginTop:6px;
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? "transparent" : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? blue[400] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
   
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
function EditVariationProduct() {
  const theme = useTheme();
  const navigate = useNavigate()
  const location = useLocation();
  const [name, setName] = useState(location.state.name);
  const [description, setDescription] = useState(location.state.description);
  const [sku, setSku] = useState(location.state.sku);
  const skuNumber = Number(sku);
  const [imageData, setImageData] = useState(location.state.image);
  const [variationName, setVariationName] = useState("");
  const [variationDescription, setVariationDescription] = useState("");
  const [variationSku, setVariationSku] = useState("");
  const variationSkuNumber = Number(variationSku);
  const [variationImageData, setVariationImageData] = useState([]);
  const [variationPrice, setVariationPrice] = useState("");
  const variationPriceNumber = Number(variationPrice);
  const [stock, setStock] = useState(true);
  const [selectedGalleryImages, setSelectedGalleryImages] = useState([]);
  const [imageFile, setImageFile] = useState(location.state.image);
  const [variationId, setVariationId] = useState(null)
  const [variationPopup, setVariationPopup] = useState(false)
  const [viewId, setViewId] = useState(false)
  const [productView, setProductView] = useState(true)
  const [variation, setVariation] = useState(false);
  const [loading, setLoading] = useState(false)
  const [multipleVariation, setMultipleVariation] = useState(
    location.state.variation
  );
  let variationEdit = (e, i) => {
    setVariationId(i);
    setVariationPopup(true)
    const changeVariation = multipleVariation.find((v, index) => index === i);
    setVariationName(changeVariation.name);
    setVariationDescription(changeVariation.description);
    setVariationSku(changeVariation.sku);
    setVariationPrice(changeVariation.price);
    setStock(changeVariation.instock);
    setSelectedGalleryImages(changeVariation.images)
    setVariationImageData(changeVariation.images)
    console.log(changeVariation);
    handleMenuClose(i)
    // setVariationImageData(changeVariation.images);
    // setSelectedGalleryImages(changeVariation.images);
  };
  let updateEdit = () => {
    setLoading(true)
    const updatedVariation = {
      _id: variationId,
      name: variationName,
      description: variationDescription,
      sku: variationSkuNumber,
      images: selectedGalleryImages.map((e) => {
        return e.file ? e.file : e;
      }), // Update this line
      price: variationPriceNumber,
      instock: stock === true,
    };

    const newUpdatedVariation = multipleVariation.map((v) =>
      v._id === updatedVariation._id ? updatedVariation : v
    );
    var formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("sku", skuNumber);
    formData.append("image", imageFile);
    formData.append("variation", JSON.stringify(newUpdatedVariation));
    newUpdatedVariation.forEach((data) => {
      console.log(data.images, imageFile);
      return data.images?.forEach((image) => formData.append("images", image.file));
    });
    newUpdatedVariation.forEach((data) => {
      console.log(data.images);
    });

    var requestOptions = {
      method: "PUT",
      body: formData,
    };

    fetch(
      `${LocalUrl}/VariableProduct/edit-product/${location.state._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // window.location.reload();
        console.log(result);
        setName("");
        setDescription("");
        setSku("");
        setMultipleVariation([]);
        setSelectedGalleryImages([]);
        toast.success(result.message);
        navigate("/dashboard/variable-product")
        setLoading(false)
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false)
      });
  };
  const handleGalleryImageChange = (e) => {
    const files = Array.from(e.target.files);
    const gFiles = e.target.files[0];
    setVariationImageData((gallery) => [...gallery, gFiles]);
    const images = files.map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));
    setSelectedGalleryImages((prevImages) => [...prevImages, ...images]);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageData({ file, url: URL.createObjectURL(file) });

    setImageFile(file);
  };
  const removeImage = (index) => {
    const updatedImages = [...selectedGalleryImages];
    updatedImages.splice(index, 1);
    setSelectedGalleryImages(updatedImages);
  };
  const [anchorEls, setAnchorEls] = useState(Array(multipleVariation.length).fill(null));

  const handleMenuClick = (event, index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleMenuClose = (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };
  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  const handleDelete = (i) => {
    // Handle delete action
    const updatedVariton = [...multipleVariation];
    updatedVariton.splice(i, 1);
    setMultipleVariation(updatedVariton)
    handleMenuClose(i);
  };

  const handleView = (e, i) => {
    // Handle view action
    console.log("view");
    handleMenuClose(i);
    setViewId(true)
    setVariationName(e.name)
    setVariationDescription(e.description)
    setVariationPrice(e.price)
    setVariationSku(e.sku)
    setStock(Boolean(e.instock))
    setVariationImageData(e.images)
    setSelectedGalleryImages(e.images)
  };
  const canceledView = () => {
    setViewId(null)
    setVariationId(null)
    setVariationPopup(false)
    setVariationName("")
    setVariationDescription("")
    setVariationPrice("")
    setVariationSku("")
    setStock(null)
    setVariationImageData([])
    setSelectedGalleryImages([])
  }
  const addVariations = () => {
    if (!name || !description || !sku || !imageData) {
      return
    }
    setVariation(!variation);
    setProductView(!productView)
  };
  const saveVariation = () => {
    if (!variationName || !variationDescription || !variationSkuNumber || !variationPriceNumber || !stock) {
      toast.error("Please Fill Variation Input");
      return;
    } else {

      const newImages = selectedGalleryImages.filter(image => image.file);

      if (newImages.length) {
        UploadImage(newImages)
      } else {
        const variationData = {
          name: variationName,
          description: variationDescription,
          sku: variationSkuNumber,
          price: variationPriceNumber,
          instock: stock,
          images: selectedGalleryImages?.map((e) => {
            return e;
          }),
        };
        if (typeof variationId !== 'undefined' && variationId !== null) {
          console.log("if");
          const updatedVariations = [...multipleVariation];
          updatedVariations[variationId] = variationData;
          setMultipleVariation(updatedVariations);

        } else {
          console.log("else");
          // Add new variation if variationId is not defined


          setMultipleVariation([...multipleVariation, variationData]);
        }

        // Reset form fields
        setVariationId(null);
        setVariationPopup(false)
        setVariationName("");
        setVariationDescription("");
        setVariationSku("");
        setVariationPrice("");
        setVariationImageData([]);
        setStock(null);
        setSelectedGalleryImages([]);
      }
      // Check if variationId is defined (editing existing variation)

    }
  };
  const UploadImage = async (newImages) => {
    console.log(newImages);
    var formData = new FormData();
    newImages?.map((image) => formData.append("images", image.file));
    var requestOptions = {
      method: "POST",
      body: formData,
    };

    try {
      const response = await fetch(
        `${LocalUrl}/UploadImage/upload`,
        requestOptions
      );
      if (response.ok) {
        const result = await response.json();
        console.log(result)
        const Gallery = selectedGalleryImages.filter((e) => !e.file)
        const variationData = {
          name: variationName,
          description: variationDescription,
          sku: variationSkuNumber,
          price: variationPriceNumber,
          instock: stock,
          images: [...Gallery, ...result.Images],
        };
        if (typeof variationId !== 'undefined' && variationId !== null) {
          console.log("if");
          const updatedVariations = [...multipleVariation];
          updatedVariations[variationId] = variationData;
          setMultipleVariation(updatedVariations);

        } else {
          console.log("else");
          // Add new variation if variationId is not defined


          setMultipleVariation([...multipleVariation, variationData]);
        }

        // Reset form fields
        setVariationId(null);
        setVariationPopup(false)
        setVariationName("");
        setVariationDescription("");
        setVariationSku("");
        setVariationPrice("");
        setVariationImageData([]);
        setStock(null);
        setSelectedGalleryImages([]);
      }
      else {
        toast.error(response);
      }
    }
    catch (error) {
      console.error("Error:", error);
      toast.error("Error occurred while adding image upload");
      // setLoading(false);
    }
  }
  return loading ? <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    }}
  >
    <CircularProgress sx={{ color: "#797C8C" }} />
  </div> : (
    <Container sx={{ mt: 5 }} maxWidth="lg">
      <div role="presentation" onClick={handleClick} style={{ marginBottom: 20 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography onClick={() => {
            if (productView) {
              return
            }
            setVariation(!variation)
            setProductView(!productView)
          }} color="text.primary" sx={{ cursor: "pointer" }}>
            Add Prduct Detail
          </Typography>
          {variation ? <Typography
            color="text.primary"
          >
            Add Variation
          </Typography> : <></>}
        </Breadcrumbs>
      </div>
      {
        productView ?
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            component="form"
          >
            <Grid item xs={7.5}>
              <Box
                rowrpacing={1}
                columnspacing={{ xs: 1, sm: 2, md: 3 }}
                className="main-order-table glass-morphism"
              >
                <Grid container justifyContent={"space-between"}>
                  <Grid item sx={6} width={"48%"}>
                    <label style={{ marginBottom: "10px" }}>Product Sku</label>
                    <TextField
                      required
                      id="outlined-basic"
                      placeholder="Product Sku"
                      variant="outlined"
                      fullWidth
                      type="number"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/[^0-9]/g, "");
                        setSku(numericValue);
                      }}
                      value={sku}
                    />
                  </Grid>
                  <Grid item sx={6} width={"48%"} >
                    <label style={{ marginBottom: "10px" }}>Product Name</label>
                    <TextField
                      sx={{ marginTop: "10px" }}
                      required
                      id="outlined-basic"
                      placeholder="Product Name"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: 2 }}>
                  <label style={{ marginBottom: "10px" }}>
                    Product Description
                  </label>
                  <StyledTextarea
                    minRows={2}
                    maxRows={5}
                    fullWidth
                    aria-label="Product Description"
                    value={description}
                    theme={theme}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {/* <TextField
                  required
                  id="outlined-basic"
                  placeholder="Product Description"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                /> */}
                </Grid>
                <Grid container justifyContent={"end"} sx={{ marginTop: "15px" }}>
                  <label style={{ marginBottom: "10px", opacity: 0 }}>Product Sku</label>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={addVariations}
                    // fullWidth
                    sx={{ height: "45px" }}
                  >
                    {"Add Variation"}
                  </Button>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                rowrpacing={1}
                columnspacing={{ xs: 1, sm: 2, md: 3 }}
                className="main-order-table glass-morphism"
              >
                <Typography variant="h6">Product Image</Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: 20
                  }}
                >
                  {imageData ? (
                    <>
                      <span style={{ marginTop: "10px", position: "relative" }}>
                        <div style={{
                          position: "absolute", right: -4, zIndex: 9, top: -2, background: "#fff", padding: "1px",
                          display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%"
                        }} onClick={() => {
                          setImageData("")
                          setImageFile("")
                        }}>
                          <CloseIcon fontSize='20' sx={{ cursor: "pointer", color: "#000" }} />
                        </div>
                        <img
                          src={imageData.url ? imageData.url : `${ImageLocalUrl}/${imageData}`}
                          alt=''
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "10px",
                            marginTop: 5,
                          }}
                        />
                      </span>
                      <Grid item sx={{ width: "100px", height: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <label for="upload-photo" style={{
                          border: "2px dashed #fff",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          padding: "5px",
                          borderRadius: "6px",
                          cursor: "pointer",
                          height: "100%",
                          justifyContent: "center"
                        }}>
                          <img src={Plus} alt="" width={20} height={20} />
                          <Typography variant="p" sx={{ fontSize: "12px", fontWeight: "500" }}>Change Image</Typography>
                        </label>
                        <input
                          id="upload-photo"
                          style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                          fullWidth
                          type="file"
                          onChange={handleImageChange}
                        // ref={fileInputRef}
                        />
                      </Grid>
                    </>
                  ) : null}
                </div>
                <Grid container sx={{ marginTop: 2 }}>
                  {imageData ?
                    <>

                      {/* <label
                        htmlFor="upload-image"
                        style={{
                          border: "1px dashed rgb(89 91 103)",
                          width: "100%",
                          padding: 10,
                          borderRadius: 10,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={Upload}
                          alt=""
                          width="20"
                          style={{ marginRight: 10 }}
                        />
                        Change Image
                      </label>
                      <input
                        id="upload-image"
                        style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                        fullWidth
                        type="file"
                        onChange={handleImageChange}
                      /> */}
                    </> :
                    <>
                      <label for="upload-photo" className="image-upload-customize">
                        <img src={Gallery} alt="" width={80} height={80} />
                        <Typography variant="h6">Upload Product Image</Typography>
                      </label>
                      <input
                        id="upload-photo"
                        style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                        fullWidth
                        type="file"
                        onChange={handleImageChange}
                      // ref={fileInputRef}
                      />
                    </>
                  }

                </Grid>
              </Box>
            </Grid>
            {/* <Grid item xs={4}>
              <Box
                rowrpacing={1}
                columnspacing={{ xs: 1, sm: 2, md: 3 }}
                className="main-order-table glass-morphism"
              >
                <Typography variant="h6">Product Image</Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  {imageData ? (
                    <span style={{ marginTop: "10px" }}>
                      <img
                        src={imageData.url ? imageData.url : `${ImageLocalUrl}/${imageData}`}
                        alt=''
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "10px",
                          marginTop: 5,
                        }}
                      />
                    </span>
                  ) : null}
                </div>
                <Grid container sx={{ marginTop: 2 }}>
                  <label for="upload-photo" className="image-upload-customize">
                    <img src={Gallery} alt="" width={80} height={80} />
                    <Typography variant="h6">Upload Product Image</Typography>
                  </label>
                  <input
                    id="upload-photo"
                    style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                    fullWidth
                    type="file"
                    onChange={handleImageChange}
                  // ref={fileInputRef}
                  />
                </Grid>
              </Box>
            </Grid> */}
          </Grid>
          : null
      }
      {variation ?
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          component="form"
        >
          <Grid item xs={7.5}>
            <Box
              rowrpacing={1}
              columnspacing={{ xs: 1, sm: 2, md: 3 }}
              className="main-order-table glass-morphism"
            >

              <Grid container sx={{ marginTop: 2 }} justifyContent={"space-between"}>
                <Grid item sx={6} width={"48%"} >
                  <label style={{ marginBottom: "10px" }}>Varition Name</label>
                  <TextField
                    required
                    id="outlined-basic"
                    placeholder="Variation Name"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setVariationName(e.target.value)}
                    value={variationName}
                    disabled={viewId ? true : false}
                  />
                </Grid>
                <Grid item sx={6} width={"48%"}>
                  <label style={{ marginBottom: "10px" }}>Variation Sku</label>
                  <TextField
                    required
                    id="outlined-basic"
                    placeholder="Variation Sku"
                    variant="outlined"
                    fullWidth
                    type="number"
                    disabled={viewId ? true : false}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9]/g, "");
                      setVariationSku(numericValue);
                    }}
                    value={variationSku}
                  />
                </Grid>
              </Grid>

              <Grid container sx={{ marginTop: 2 }} justifyContent={"space-between"}>
                <Grid item sx={6} width={"48%"}>
                  <label style={{ marginBottom: "10px" }}>Variation Price</label>
                  <TextField
                    required
                    id="outlined-basic"
                    placeholder="Variation Price"
                    variant="outlined"
                    fullWidth
                    type="number"
                    disabled={viewId ? true : false}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/[^0-9]/g, "");
                      setVariationPrice(numericValue);
                    }}
                    value={variationPrice}
                  />
                </Grid>
                <Grid
                  item sx={6} width={"48%"}
                >
                  <Box>
                    <label style={{ marginBottom: "10px" }}>Variation Stock</label>
                    <FormGroup>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={stock}

                        name="radio-buttons-group"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          disabled={viewId ? true : false}
                          control={
                            <Radio
                              sx={{
                                color: "#FFF",
                                "&.Mui-checked": { color: "#3f51b5" },
                              }}
                            />
                          }
                          label="In Stock"
                          onChange={(e) => setStock(e.target.value)}
                        />
                        <FormControlLabel
                          value="false"
                          disabled={viewId ? true : false}
                          control={
                            <Radio
                              sx={{
                                color: "#FFF",
                                "&.Mui-checked": { color: "#3f51b5" },
                              }}
                            />
                          }
                          label="Out Stock"
                          onChange={(e) => setStock(e.target.value)}
                        />
                      </RadioGroup>
                    </FormGroup>
                  </Box>
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: 2 }}>
                <label style={{ marginBottom: "10px" }}>
                  Variation Description
                </label>
                {/* <TextField
                required
                id="outlined-basic"
                placeholder="Variation Description"
                variant="outlined"
                fullWidth
                onChange={(e) => setVariationDescription(e.target.value)}
                value={variationDescription}
              /> */}
                <StyledTextarea
                  disabled={viewId ? true : false}
                  minRows={2}
                  maxRows={5}
                  fullWidth
                  aria-label="Variation Description"
                  value={variationDescription}
                  theme={theme}
                  onChange={(e) => setVariationDescription(e.target.value)}
                />
              </Grid>
              <Grid container sx={{ marginTop: 2 }} flexDirection={"column"}>
                <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                  Variation Image
                </Typography>
                {
                  selectedGalleryImages.length >= 1 ? null :
                    <Button sx={{ width: "25%" }}
                      component="label"
                      role={undefined}
                      variant="contained"
                      color='info'
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      disabled={viewId ? true : false}
                    >
                      Upload file
                      <VisuallyHiddenInput type="file" onChange={handleGalleryImageChange} />
                    </Button>
                }
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: 5
                  }}
                >
                  {selectedGalleryImages &&
                    selectedGalleryImages.map((image, index) => (
                      <div style={{
                        position: "relative",
                      }}>
                        {viewId ? <></> : <div style={{
                          position: "absolute", right: 4, zIndex: 9, top: 2, background: "#fff", padding: "1px",
                          display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%"
                        }} onClick={() => removeImage(index)}>
                          <CloseIcon fontSize='20' sx={{ cursor: "pointer", color: "#000" }} />
                        </div>}

                        <img
                          key={index}
                          src={image.url}
                          alt={`Selected ${index + 1}`}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "6px",
                            marginTop: 10,
                            marginRight: 10, zIndex: 1
                          }}
                        />
                      </div>
                    ))}
                  {
                    selectedGalleryImages.length >= 1 ? <Grid item sx={{ width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <label for="upload-photo" style={{
                        border: "2px dashed #fff",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "5px",
                        borderRadius: "6px",
                        cursor: "pointer",
                        height: "100%",
                        justifyContent: "center"
                      }}>
                        <img src={Plus} alt="" width={10} height={10} />
                        {/* <Typography variant="p" sx={{ fontSize: "12px", fontWeight: "500" }}>Upload More</Typography> */}
                      </label>
                      <input
                        id="upload-photo"
                        style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                        fullWidth
                        type="file"
                        onChange={handleGalleryImageChange}
                      // ref={fileInputRef}
                      />
                    </Grid> : <></>
                  }
                </div>
              </Grid>
              <Grid container sx={{ marginTop: 2 }} justifyContent={"end"}>
                {viewId ? <Button
                  variant="contained"
                  // fullWidth
                  onClick={() => {
                    canceledView();
                  }}
                  color="secondary"
                >
                  Canceled
                </Button> : <Button
                  variant="contained"
                  // fullWidth
                  onClick={() => {
                    saveVariation();
                  }}
                  color="secondary"
                >
                  Save Variation
                </Button>}

              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4}>

            {multipleVariation.length ? (
              <Box
                rowrpacing={1}
                columnspacing={{ xs: 1, sm: 2, md: 3 }}
              // component={Paper}
              // className="main-order-table glass-morphism"
              >
                <TableContainer sx={{ mt: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Image</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Sku</TableCell>
                        <TableCell align="left">Price</TableCell>
                        <TableCell align="left">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {multipleVariation.map((e, i) => (
                        <TableRow key={i}>
                          <TableCell align="left" sx={{ fontSize: "16px", fontWeight: "400" }}>
                            <img src={e.images[0].url ? e.images[0].url : `${ImageLocalUrl}/${e.images[0]}`} width={40} height={40} />
                          </TableCell>
                          <TableCell align="left" sx={{ fontSize: "16px", fontWeight: "400" }}>{e.name}</TableCell>
                          <TableCell align="left" sx={{ fontSize: "16px", fontWeight: "400" }}>{e.sku}</TableCell>
                          <TableCell align="left" sx={{ fontSize: "16px", fontWeight: "400" }}>{e.price}</TableCell>
                          <TableCell align="left" sx={{ fontSize: "16px", fontWeight: "400" }}>
                            {/* <Button variant='contained' color='secondary' onClick={handleMenuClick}> */}
                            <MoreVertIcon onClick={(event) => handleMenuClick(event, i)} sx={{ cursor: "pointer" }} />
                            {/* </Button> */}
                            <Menu
                              anchorEl={anchorEls[i]}
                              open={Boolean(anchorEls[i])}
                              onClose={() => handleMenuClose(i)}

                            >
                              <MenuItem onClick={() => variationEdit(e, i)}>Edit</MenuItem>
                              <MenuItem onClick={() => handleDelete(i)}>Delete</MenuItem>
                              <MenuItem onClick={() => handleView(e, i)}>View</MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", marginTop: "10px" }}>
                  <Button
                    variant="contained"
                    // fullWidth
                    onClick={() => {
                      updateEdit();
                    }}
                    color="secondary"
                  >Update Product
                  </Button>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        : null}
      <ViewVariation open={viewId} handleClose={canceledView} variationName={variationName}
        selectedGalleryImages={selectedGalleryImages} variationDescription={variationDescription} variationSku={variationSku} variationPrice={variationPrice} stock={stock} />
      <EditVariationPopup setVariationName={setVariationName} setVariationDescription={setVariationDescription} setVariationSku={setVariationSku} setVariationPrice={setVariationPrice} setStock={setStock}
        updateEdit={saveVariation} removeImage={removeImage} handleGalleryImageChange={handleGalleryImageChange} open={variationPopup} handleClose={canceledView} variationName={variationName}
        selectedGalleryImages={selectedGalleryImages} variationDescription={variationDescription} variationSku={variationSku} variationPrice={variationPrice} stock={stock} />
    </Container>
  );
}
export default EditVariationProduct;
