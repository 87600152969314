import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Container, FormControlLabel, FormGroup, Grid, Modal, Radio, RadioGroup, TextField, TextareaAutosize, Typography, useTheme } from "@mui/material";
import styled from "styled-components";
// import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Plus } from '../../config/icon';
import { ImageLocalUrl } from '../../config/env';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
};

const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
};

const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    // margin:10px 0px;
    marginTop:6px;
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? "transparent" : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[200] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]
        };
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
     
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "55%",
    height: "90%",
    padding: "5px 10px",
    borderRadius: "10px",
    backgroundColor: "#111633",
    color: "#cbccd2",
    boxShadow: " 0px 0px 2px #6a7199",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
};
function EditVariationPopup({ variationName, variationDescription, variationPrice, variationSku, stock, selectedGalleryImages, open, handleClose, handleGalleryImageChange, removeImage, updateEdit,
    setVariationName, setVariationDescription, setVariationSku, setVariationPrice, setStock }) {
    const theme = useTheme()
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    ...style,
                    [theme.breakpoints.down("md")]: {
                        width: "90%",
                        height: "90%",
                    },
                    [theme.breakpoints.down("sm")]: {
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        width: "90%",
                        height: "90%",
                    },
                }}
            >
                <CloseIcon onClick={handleClose}
                    style={{
                        float: "right",
                        cursor: "pointer",
                    }}
                />
                <Container sx={{ mt: 5, height: "80%" }} maxWidth="lg">
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <Box
                                component="form"
                                rowrpacing={1}
                                columnspacing={{ xs: 1, sm: 2, md: 3 }}
                            // className="main-order-table glass-morphism"
                            >
                                <Grid
                                    container
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        mb: "30px",
                                    }}
                                >
                                    <Typography variant="h4">
                                        Edit Variation
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box
                                        rowrpacing={1}
                                        columnspacing={{ xs: 1, sm: 2, md: 3 }}
                                        className="main-order-table glass-morphism"
                                    >

                                        <Grid container sx={{ marginTop: 2 }} justifyContent={"space-between"}>
                                            <Grid item sx={6} width={"48%"} >
                                                <Typography variant="h6" sx={{ marginBottom: "10px" }}>Variation Name</Typography>
                                                <TextField
                                                    required
                                                    id="outlined-basic"
                                                    placeholder="Variation Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={variationName}
                                                    onChange={(e) => setVariationName(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item sx={6} width={"48%"}>
                                                <Typography variant="h6" sx={{ marginBottom: "10px" }}>Variation Sku</Typography>
                                                <TextField
                                                    required
                                                    id="outlined-basic"
                                                    placeholder="Variation Sku"
                                                    variant="outlined"
                                                    fullWidth
                                                    type="number"

                                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                                    value={variationSku}
                                                    onChange={(e) => setVariationSku(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ marginTop: 2 }} justifyContent={"space-between"}>
                                            <Grid item sx={6} width={"48%"}>
                                                <Typography variant="h6" sx={{ marginBottom: "10px" }}>Variation Price</Typography>
                                                <TextField
                                                    required
                                                    id="outlined-basic"
                                                    placeholder="Variation Price"
                                                    variant="outlined"
                                                    fullWidth
                                                    type="number"

                                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                                    onChange={(e) => {
                                                        const numericValue = e.target.value.replace(/[^0-9]/g, "");
                                                        setVariationPrice(numericValue);
                                                    }}
                                                    value={variationPrice}
                                                />
                                            </Grid>
                                            <Grid
                                                item sx={6} width={"48%"}
                                            >
                                                <Box>
                                                    <Typography variant="h6" sx={{ marginBottom: "10px" }}>Variation Stock</Typography>
                                                    <FormGroup>
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            value={stock}

                                                            name="radio-buttons-group"
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                // justifyContent: "center",
                                                                flexDirection: "row",
                                                            }}
                                                        >
                                                            <FormControlLabel
                                                                value="true"

                                                                control={
                                                                    <Radio
                                                                        sx={{
                                                                            color: "#FFF",
                                                                            "&.Mui-checked": { color: "#3f51b5" },
                                                                        }}
                                                                    />
                                                                }
                                                                label="In Stock"
                                                                onChange={(e) => setStock(e.target.value)}
                                                            />
                                                            <FormControlLabel
                                                                value="false"

                                                                control={
                                                                    <Radio
                                                                        sx={{
                                                                            color: "#FFF",
                                                                            "&.Mui-checked": { color: "#3f51b5" },
                                                                        }}
                                                                    />
                                                                }
                                                                label="Out Stock"
                                                                onChange={(e) => setStock(e.target.value)}
                                                            />
                                                        </RadioGroup>
                                                    </FormGroup>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ marginTop: 2 }}>
                                            <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                                                Variation Description
                                            </Typography>
                                            <StyledTextarea

                                                minRows={2}
                                                maxRows={5}
                                                fullWidth
                                                aria-label="Variation Description"
                                                value={variationDescription}
                                                theme={theme}
                                                onChange={(e) => setVariationDescription(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid container sx={{ marginTop: 2 }} flexDirection={"column"}>
                                            <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                                                Variation Image
                                            </Typography>
                                            {
                                                selectedGalleryImages.length >= 1 ? null :
                                                    <Button sx={{ width: "25%" }}
                                                        component="label"
                                                        role={undefined}
                                                        variant="contained"
                                                        color='info'
                                                        tabIndex={-1}
                                                        startIcon={<CloudUploadIcon />}
                                                    // disabled={viewId ? true : false}
                                                    >
                                                        Upload file
                                                        <VisuallyHiddenInput type="file" onChange={handleGalleryImageChange} />
                                                    </Button>
                                            }
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    // justifyContent: "space-between",
                                                    flexWrap: "wrap",
                                                    gap: 5
                                                }}
                                            >
                                                {selectedGalleryImages &&
                                                    selectedGalleryImages.map((image, index) => (
                                                        <div style={{
                                                            position: "relative",
                                                        }}>
                                                            <div style={{
                                                                position: "absolute", right: 4, zIndex: 9, top: 2, background: "#fff", padding: "1px",
                                                                display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%"
                                                            }} onClick={() => removeImage(index)}>
                                                                <CloseIcon fontSize='20' sx={{ cursor: "pointer", color: "#000" }} />
                                                            </div>

                                                            <img
                                                                key={index}
                                                                src={image.url ? image.url : `${ImageLocalUrl}/${image}`}
                                                                alt={`Selected ${index + 1}`}
                                                                style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                    borderRadius: "6px",
                                                                    marginTop: 10,
                                                                    marginRight: 10, zIndex: 1
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                {
                                                    selectedGalleryImages.length >= 1 ? <Grid item sx={{ width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <label for="upload-photo" style={{
                                                            border: "2px dashed #fff",
                                                            width: "100%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            flexDirection: "column",
                                                            padding: "5px",
                                                            borderRadius: "6px",
                                                            cursor: "pointer",
                                                            height: "100%",
                                                            justifyContent: "center"
                                                        }}>
                                                            <img src={Plus} alt="" width={10} height={10} />
                                                            {/* <Typography variant="p" sx={{ fontSize: "12px", fontWeight: "500" }}>Upload More</Typography> */}
                                                        </label>
                                                        <input
                                                            id="upload-photo"
                                                            style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                                                            fullWidth
                                                            type="file"
                                                            onChange={handleGalleryImageChange}
                                                        // ref={fileInputRef}
                                                        />
                                                    </Grid> : <></>
                                                }
                                            </div>
                                        </Grid>
                                        <Grid container sx={{ marginTop: 2 }}>
                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", width: "100%" }}>
                                                <Button
                                                    variant="contained"
                                                    // fullWidth
                                                    onClick={() => {
                                                        updateEdit();
                                                    }}
                                                    color="secondary"
                                                >Save Variation Changes
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Modal>
    )
}
export default EditVariationPopup